.formUploadContent {
  max-width: 45rem;
}

.button {
  margin-top: 1rem;
  float: right;
}

.fileuploader-container {
  border: 1px solid #d3d3d3;
  margin: 20px 00px 0 00px;
}
